import '../../App.css';
import './active_mail.css';
import { Layout } from "antd";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../config/config';

const { Header, Content, Footer } = Layout;
const { useLocation } = require('react-router-dom');

const ActiveMail = () => {
    const [activeStatus, setActiveStatus] = useState('激活中...');

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const code = queryParams.get('code');

    useEffect(() => {
        if (code) {
            axios.get(`${server}/user/activate_mail?code=${code}`)
                .then(res => {
                    setActiveStatus(res.data.msg);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [code]);

    return <Layout>
        <Header className="header">
            <div className="header-title">我有好多钱</div>
        </Header>
        <Content className="content">
            <img className="content-BG" src={`${process.env.PUBLIC_URL}/image_2.png`} alt='content-BG' />
            <div className="card">
                <div className='inner-card'>
                    <div className="inner-card-title">
                        {activeStatus}
                    </div>
                </div>
            </div>
        </Content>
        <Footer className="footer">
            <div className="footer-title">浙ICP备2024129612号</div>
            <div className="footer-title">浙ICP备2024129612号</div>
        </Footer>
    </Layout>
}

export default ActiveMail;