// 网站router
import { createBrowserRouter } from "react-router-dom";
import ImportBill from "../pages/import_bill/import";
import ActiveMail from "../pages/active_mail/active_mail";
const router = createBrowserRouter([
    {
        path: "/import_bill",
        element: <ImportBill />,
    }, {
        path: "/activate_mail",
        element: <ActiveMail />,
    }, {
        path: "/",
        element: <div>igotmoney</div>,
    }
]);

export default router;