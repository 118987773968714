// import wxLogin from 'http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
import '../../App.css';
import './import.css';
import { Layout } from "antd";
import { useState } from 'react';
import classNames from 'classnames';
const { Header, Content, Footer } = Layout;

async function login() {
    // 微信登录实例
    // var obj = new wxLogin({
    //     self_redirect: true,
    //     id:"login_container", 
    //     appid: "", 
    //     scope: "snsapi_login", 
    //     redirect_uri: "",
    //     state: "",
    //     style: "",
    //     href: ""
    // });
}

const ImportBill = () => {
    const [loggedIn, setLoggedIn] = useState(true);

    return <Layout>
        <Header className="header">
            <div className="header-title">我有好多钱</div>
        </Header>
        <Content className="content">
            <img className="content-BG" src={`${process.env.PUBLIC_URL}/image_2.png`} alt='content-BG' />
            {loggedIn ?
                // 上传文件
                <div className="card">
                    <div className={classNames('inner-card', { 'login-card': !loggedIn })}>
                        <div className="inner-card-import-wrapper">
                            <img className="img-bill" src={`${process.env.PUBLIC_URL}/bill.png`} alt='img-bill' />
                            <div className="inner-card-import-text-wrapper">
                                <div className="inner-card-title">请上传账单明细</div>
                                <div className="inner-card-text">支持.csv、.xlsx和.xls格式，文件最大10MB</div>
                            </div>
                        </div>
                        <div className="inner-card-bottom">
                            <div className="inner-card-bottom-text-wrapper">
                                <span className="inner-card-text">当前支持微信、支付宝导出的账单明细，点击查看</span>
                                <a href="https://www.baidu.com" className="inner-card-text link">导出说明</a>
                            </div>
                        </div>
                    </div>
                </div>
                :
                // 登录
                <div className="card">
                    <div className={classNames('inner-card', { 'login-card': !loggedIn })}>
                        <img className="img-qr" src={`${process.env.PUBLIC_URL}/qr.jpg`} alt='img-qr' />
                        <div className="login-content">
                            <div className="login-prompt">请使用微信<br />扫描二维码登录</div>
                            <img className="img-wx" src={`${process.env.PUBLIC_URL}/wx.png`} id="login_container" alt='img-wx' />
                        </div>
                    </div>
                </div>
            }
        </Content>
        <Footer className="footer">
            <div className="footer-title">浙ICP备2024129612号</div>
            <div className="footer-title">浙ICP备2024129612号</div>
        </Footer>
    </Layout>
}

export default ImportBill;